import React, { Component } from 'react'
import algoliasearch from 'algoliasearch'
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import { Index, connectStateResults, Hits, InstantSearch, SearchBox } from 'react-instantsearch-dom'
import '@algolia/autocomplete-theme-classic'
import { Helmet } from "react-helmet"

// Компоненты
import Menu from "../components/menu.js"
import Seo from "../components/seo.js"
import Footer from "../components/footer.js"

const searchClient = algoliasearch(
  'V9DR2F1OKZ',
  '8fb8d91126aea3de55dd00a408b8134a'
);

const Hit = ({ hit }) => <a href={'/'+hit.slug+'/'}>{hit.title+' →'}</a>;

/*const Hit = ({ hits }) => (
  <ol>
    {hits.map(hit => (
      <li key={hit.objectID}>
        <a href={'/'+hit.slug+'/'}>{hit.title+' →'}</a>
      </li>
    ))}
  </ol>
);

const CustomHits = connectHits(Hit);*/

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) =>
    res && res.nbHits > 0 ? children : (<h6 className="mt-[20px] mb-[60px]">Ничего не найдено по запросу: <b>"{state.query}"</b></h6>)
)

class Search extends Component {
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }

    autocomplete({
      container: '#autocomplete-page',
      placeholder: 'Найти...',
      detachedMediaQuery: 'none',
      getSources({ query }) {
        return [
          {
            sourceId: 'products',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'Pages',
                    query,
                    params: {
                      hitsPerPage: 5,
                      attributesToSnippet: ['title:10', 'excerpt:35', 'slug'],
                      snippetEllipsisText: '…',
                    },
                  },
                  {
                    indexName: 'Posts',
                    query,
                    params: {
                      hitsPerPage: 5,
                      attributesToSnippet: ['title:10', 'excerpt:35', 'slug'],
                      snippetEllipsisText: '…',
                    },
                  },
                ],
              });
            },
            templates: {
              item({ item, components, html }) {
                return html`<div class="aa-ItemWrapper">
                <a href="/${item.slug}/">
                  <div class="aa-ItemContent">
                    <div class="aa-ItemContentBody">
                      <div class="aa-ItemContentTitle">
                        ${components.Highlight({
                          hit: item,
                          attribute: 'title',
                        })}
                      </div>
                    </div>
                  </div>
                  </a>
                </div>`;
              },
            },
            getItemUrl({ item }) {
              return item.slug;
            },
          },
        ];
      },
      renderNoResults({ render, html, state }, root) {
        render(
          html`
            <div class="aa-PanelLayout aa-Panel--scrollable">
              Нет результатов по запросу "${state.query}".
            </div>
          `,
          root
        )
      },
      onStateChange({ state }) {
        const wage = document.querySelector('#autocomplete-page .aa-Input');
        if(wage){
          wage.addEventListener('keypress', (e) => {
              if (e.key === 'Enter') {
                window.open('/search/?q='+wage.value, '_self');
              }
          });
        }
      },
    });
  }

  /*constructor(props) {
    super(props);

    this.state = {
      params: (new URL(document.location)).searchParams,
    };
  }*/

  render() {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('q')
    return (
      <>
        <Seo title="Поиск по сайту | SleepAdvisor" description="" />
        <Helmet>
          <script type="application/ld+json">{`{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Главная","item": "https://sleepadvisor.ru"},{"@type": "ListItem","position": 2,"name": "Результаты поиска"}]}`}</script>
        </Helmet>
        <Menu />
        <div className="hero all-page">
          <div className="container max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
            <div className="breadcrumb-block">    
              <div className="breadcrumbs">
                <nav aria-label="breadcrumb">
                  <ol>
                    <li className="breadcrumb-item"><a href="/">Главная</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Результаты поиска</li>
                  </ol>
                </nav>
              </div>
            </div>
            <h1>Результаты поиска</h1>
          </div>
        </div>
        <div className="container max-w-[540px] md:max-w-[750px] mx-auto px-[15px] py-16">
          <div className="flex justify-between">
            <div id="autocomplete-page" className="w-full"></div>
            <button className="button button--small button_primary ml-8 cursor-pointer" onMouseDown={(e) => {
              e.preventDefault();
              const wage = document.querySelector('#autocomplete-page .aa-Input');
              window.open('/search/?q='+wage.value, '_self');
            }}>Поиск</button>
          </div>
          <InstantSearch
            indexName={'Pages'}
            searchClient={searchClient}
            searchState={{
              query: token,
            }}
          >
            <SearchBox css={{display:'none'}} />
            <Results><h6 className="mt-[20px] mb-[60px]">Результаты поиска по запросу: <b>"{token}"</b></h6></Results>
            {/*{token && <Hits hitComponent={Hit} />}*/}

            {token && <Index indexName="Pages">
              <Hits hitComponent={Hit} />
            </Index>}

            {token && <Index indexName="Posts">
              <Hits hitComponent={Hit} />
            </Index>}
            {/*<CustomHits />*/}
          </InstantSearch>
        </div>
        <Footer />
      </>
    )
  }
}

export default Search